import React from 'react';
import Headshot from '../Images/Headshot.jpeg'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../App.css'
// import App from '../App';
function Home() {

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{flex: 1}}></div>
            <div style={{flex: 5}}>
                <img style={{borderRadius:"50%", padding:8}} src={Headshot} height="150px" alt="This is Me" />
                <h3 className="header">Welcome!</h3>
                <br></br>
                <p class="paragraph" style={{textAlign: "left"}}>
                    I am TJ Driver, a Senior at the University of Wisconsin-Madison.  I am majoring in Finance, Investment, and Banking,
                    while also pursuing a Certificate in Computer Science.
                </p>
                <p class="paragraph" style={{ textAlign: "left" }}>
                    My interests include web / app development, data analysis, artificial intelligence, blockchain technologies, and cybersecurity (to name a few) - want to chat about any of those? Get in touch:
                </p>
                <Button style={{ width: 200, height: 50 }}>
                    <Link style={{ color: 'white', textDecoration: 'none'}}
                        to='#'
                        onClick={(e) => {
                            window.location = 'mailto:tjdriver@wisc.edu';
                            e.preventDefault();
                        }}
                    >
                        Start a Conversation
                    </Link>
                </Button>
                    
            </div>
            <div style={{flex: 1}}></div>
        </div>
    )
}

export default Home;

