// import logo from './logo.svg';
import './App.css';
import React from 'react';
import Home from './Pages/Home'
import AboutMe from './Pages/AboutMe'
// import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  // useParams,
} from "react-router-dom";

function App() {

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossorigin="anonymous"
      />

      <Router>
        <Navbar className="toolbar" bg="dark" variant = "dark" sticky="top" expand="lg">
          <Navbar.Brand >TJ Driver</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav variant="pills" className="mr-auto" defaultActiveKey="home">
                <Nav.Link as={Link} to="/" eventKey="home">Home</Nav.Link>
                <Nav.Link as={Link} to="/about-me" eventKey="about">About Me</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <br />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/about-me" component={AboutMe} />
        </Switch>
      </Router>
    </div>
  )
}

export default App;
