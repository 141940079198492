import React, { useRef, useState } from 'react';
import {Spring} from 'react-spring/renderprops'
import Accordion from 'react-bootstrap/Accordion'
// import Button from 'react-bootstrap/Button'



function AboutMe() {

    // use a ref to control the scrolling when accordions get cut off by bottom of page
    // const accordionExpand = useRef()
    const accord2 = useRef()
    const accord3 = useRef()

    const item0 = useRef()
    const item1 = useRef()
    const item2 = useRef()
    const item3 = useRef()
    const item4 = useRef()
    const item5 = useRef()
    const item6 = useRef()
    const item7 = useRef()
    const item8 = useRef()
    const item9 = useRef()
    
    // state to track the active index
    const [activeItem, setActiveItem] = useState('')

    const checkActive = (activeIndex) => {

        if (activeIndex === activeItem) {
            setActiveItem('')
        } else {
            // console.log('heres screen bottom', window.innerHeight + window.scrollY)
            // console.log('heres item bottom', accordionExpand.current.getBoundingClientRect().bottom)
            // console.log('------')
            // console.log(accordionExpand.current)
            // console.log(accordionExpand.current.getBoundingClientRect())
            setActiveItem(activeIndex)
            // check if the bottom of the element is off screen
            // if (accordionExpand.current.getBoundingClientRect().bottom > window.innerHeight + window.scrollY) {
            //     accordionExpand.current.scrollIntoView({ behavior: "smooth" })
            // }
            // if (activeIndex < 3) {
            //     // accordionExpand.current.scrollIntoView({ behavior: "smooth" })

            //     // this adds some padding b/c the line above scrolls it so that the top of the accordion is at the very top of the screen
            //     // window.scrollTo({top: accordionExpand.current.getBoundingClientRect().top - 100, behavior: "smooth"})
            // } else if (activeIndex < 6) {
            //     accord2.current.scrollIntoView({ behavior: "smooth" })
            // } else {
            //     accord3.current.scrollIntoView({ behavior: "smooth" })
            // }

            // third attempt -> make each accordion item have its own ref
            // console.log(eval('item'.concat(activeIndex)).current.getBoundingClientRect().bottom + eval('item'.concat(activeIndex)).current.getBoundingClientRect().y)
            // console.log(window.innerHeight + window.scrollY)
            // console.log('--------')
            // if (eval('item'.concat(activeIndex)).current.getBoundingClientRect().bottom + eval('item'.concat(activeIndex)).current.getBoundingClientRect().y > 
            //     window.innerHeight + window.scrollY) {
            //         eval('item'.concat(activeIndex)).current.scrollIntoView({ behavior: "smooth" })
            //     }

            
            
        }
        // ------------- !!!!!!!! ------------
        // NOTE ----> I think each individual accordion item has to have its own unique ref -> scrolling into view only accounts for the accordion as a whole, not the indiv
        // item that's expanded

    }

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{flex: 1}} />
            <div style={{flex: 5}}>
                <h1 className="header">About Me</h1>
                <br></br>
                <p className="paragraph" style={{textAlign: 'left'}}>
                    I am passionate about all things numbers and computers.  I have a variety of experience in the finance, 
                    operations, and computer science fields.  
                </p>
                <br />
                <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        config = {{ duration: 500 }}>
                        {props => 
                            <div style={props}>
                                <h3 className='header' style={{textAlign: 'left'}}>Experiences</h3>
                            </div>}
                    </Spring>
                <br />
                <Accordion activeKey={activeItem} >
                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        config = {{ duration: 500, delay: 500 }}>
                        {props => 
                            <div style={props}>
                                <Accordion.Item eventKey="0" onClick={() => checkActive("0")} ref={item0}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Direct Supply: Operations Intern <i>(July 2018 - November 2021)</i></h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="paragraph" style={{textAlign: 'left'}}>
                                            In a variety of operational roles, I worked with teams to handle transactional requests and update / configure order guide 
                                            information for customers, using a broad range of external and internally developed tools.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" onClick={() => checkActive("1")} ref={item1}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Computer Science Student <i>(September 2019 - May 2022)</i></h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="paragraph" style={{textAlign: 'left'}}>
                                            As a CS Certificate student at UW-Madison, I have gained experience with a variety of languages and frameworks, including Javascript, React and
                                            React Native, Python, Java, and SQL (see "Relevant Coursework" below for details).
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" onClick={() => checkActive("2")} ref={item2}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Discovery to Product (D2P) Innovation to Market Program Graduate <i>(March 2021 - July 2021)</i></h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p className="paragraph" style={{textAlign: 'left'}}>
                                            For Fitted venture (see below), participated in a hands-on entrepreneurial course teaching the concepts of the Lean Startup such as forming hypotheses, interviewing customers, and deciding when to pivot.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                            </div>}
                    </Spring>
                </Accordion>
                <br />
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config= {{ duration: 500, delay: 1000 }}
                >
                    {props => 
                        <div style={props}>
                            <h3 className='header' style={{ textAlign: 'left' }}>Projects</h3>
                        </div>
                        
                    }             
                </Spring>
                <br />
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config={{ duration: 500, delay: 1500 }}
                >
                    {props => 
                        <div style={props}>
                            <Accordion activeKey={activeItem} ref={accord2}>
                                <Accordion.Item eventKey="3" onClick={() => checkActive("3")} ref={item3}>
                                    <Accordion.Header>
                                        <h5 className="header">Fitted App (in progress)</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>      
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                A fully functioning fashion sharing app built from scratch, where users post pictures of their outfits and provide links to each of 
                                                the items to enable efficient sharing of syle information not available on other social media platforms.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                React Native, Xcode, Firebase, Figma
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Link(s):</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Demo: <a href="https://github.com/tjdriver/fitted_demo" target="_blank" rel="noreferrer">github.com</a>
                                            </p>
                                        </div>  
                                    </Accordion.Body>
                                
                                </Accordion.Item>
                                <Accordion.Item eventKey="4" onClick={() => checkActive("4")} ref={item4}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Solana NFT Collection (Wool Capital Group)</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}> 
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Served as backend engineer for launching a collection of 4,444 NFTs on the Solana blockchain, working with a friend to build the website,
                                                interact with the proper tools and smart contracts to launch the collection on Solana, and implement the functionality to allow people to mint the NFTs.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Solana CLI, Solana JS APIs, Rust, React 
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Link(s):</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Project website: <a href="https://www.woolcapitalgroup.com/" target="_blank" rel="noreferrer">woolcapitalgroup.com</a> <br />
                                                Live marketplace: <a href="https://digitaleyes.market/collections/Wool%20Capital%20Group" target="_blank" rel="noreferrer">digitaleyes.com</a>
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5" onClick={() => checkActive("5")} ref={item5}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Finance Texting Bot</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>    
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Simple automated script that pulls prices for a variety of assets every day and texts them to target list of phone numbers.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Python, Twilio, Alpha Vantage REST API, Heroku
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Link(s):</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Script code: <a href="https://gist.github.com/tjdriver/8bcf88107325ee9b12a02a1430b98a33" target="_blank" rel="noreferrer">gist.github.com</a>
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    }
                    
                </Spring>
                <br />
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config= {{ duration: 500, delay: 2000 }}
                >
                    {props => 
                        <div style={props}>
                            <h3 className='header' style={{textAlign: 'left'}}>Relevant Coursework</h3>
                        </div>
                        
                    }             
                </Spring> 
                <br />
                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}
                    config={{ duration: 500, delay: 2500 }}
                >
                    {props => 
                        <div style={props}>
                            <Accordion activeKey={activeItem} ref={accord3}>
                                <Accordion.Item eventKey="6" onClick={() => checkActive("6")} ref={item6}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Artificial Intelligence</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>  
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Through lectures and hands-on projects, learned a variety of concepts related to AI, including, but not limited to, advanced search algorithms, Natural Language Processing, and Neural Networks.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Python, Keras, TensorFlow
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7" onClick={() => checkActive("7")} ref={item7}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Building User Interfaces</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>  
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Learned principles and best practices of designing usable and high quality interfaces for web, mobile, and voice; applied these skills through projects including
                                                a course registration website and a fitness activity tracker mobile app.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                React, React Native, Node.js, Javascript, HTML, CSS, Adobe XD
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8" onClick={() => checkActive('8')} ref={item8}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Software Security</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>  
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Learned about the design of software and identification of vulnerabilities from perspective of both attacker and creator of software.  Topics included
                                                Cross Site Scripting attacks, SQL Injection, Fuzz Testing.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Linux, Java, Python, Perl, C, Vim
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9" onClick={() => checkActive("9")} ref={item9}>
                                    <Accordion.Header>
                                        <h5 className="header" style={{textAlign: 'left'}}>Computer-Based Data Management</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>  
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                Learned concepts of designing scalable and normalized databases (with focus on RDBMS) and how to create ERDs, import data, and perform a variety of queries.
                                            </p>
                                            <h7 className="header" style={{ textAlign: 'left' }}><i>Technologies / Tools Used:</i></h7>
                                            <p className="paragraph" style={{textAlign: 'left'}}>
                                                MySQL, Tableau
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    }
                </Spring>
                
                <div style={{ paddingBottom: 50 }} />
            </div>
            <div style={{flex: 1}} />
        </div> 
    )
}

export default AboutMe;